import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Banner } from './../interfaces/banner';
import { Response } from './../interfaces/response';
import {API_URL} from './config';

@Injectable({
  providedIn: 'root'
})
export class BannerService {
  constructor(
    private http: HttpClient
  ) { }
  getAllBanners(){
    const path = `${API_URL}/v1/banners`;
    return this.http.get<Response<Banner[]>>(path);
  }
}
