import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit {

  @Input() modalImage = '';

  @Output() closeAction = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    console.log(this.modalImage);
  }

  close(): void {
    this.closeAction.emit();
  }

}
