import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-btn-back',
  templateUrl: './btn-back.component.html',
  styleUrls: ['./btn-back.component.scss']
})
export class BtnBackComponent implements OnInit {

  @Output() backAction = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  back() {
    this.backAction.emit();
  }

}
