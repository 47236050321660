import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Level } from './../interfaces/level';
import { Response } from './../interfaces/response';
import {API_URL} from './config';

@Injectable({
  providedIn: 'root'
})
export class LevelService {
  constructor(
    private http: HttpClient
  ) { }
  getAllLevels(){
    const path = `${API_URL}/v1/level`;
    return this.http.get<Response<Level[]>>(path);
  }
}
